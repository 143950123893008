// Generated by Framer (b993cbf)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const cycleOrder = ["iLc8dfHly"];

const variantClassNames = {iLc8dfHly: "framer-v-17oeg27"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "tween"}};

const transformTemplate = (_, t) => `translate(-50%, -50%) ${t}`

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; title?: string }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "iLc8dfHly", title: NSkc44hTr = "TICKER", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "iLc8dfHly", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-aeSV8", classNames)} style={{display: "contents"}}>
<motion.div {...restProps} className={cx("framer-17oeg27", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"iLc8dfHly"} ref={ref} style={{backgroundColor: "rgb(255, 255, 255)", ...style}} transition={transition}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "SW50ZXItU2VtaUJvbGQ=", "--framer-font-family": "\"Inter-SemiBold\", \"Inter\", sans-serif", "--framer-font-size": "320px", "--framer-font-weight": "600", "--framer-letter-spacing": "-0.06em", "--framer-line-height": "100%", "--framer-text-color": "var(--extracted-r6o4lv)"}}>TICKER</motion.p></React.Fragment>} className={"framer-19kerlm"} fonts={["Inter-SemiBold"]} layoutDependency={layoutDependency} layoutId={"CB0D6Ib4y"} style={{"--extracted-r6o4lv": "rgb(128, 128, 128)", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline", "--framer-paragraph-spacing": "0px"}} text={NSkc44hTr} transformTemplate={transformTemplate} transition={transition} verticalAlignment={"top"} withExternalLayout/></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-aeSV8 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-aeSV8 .framer-1jecu9l { display: block; }", ".framer-aeSV8 .framer-17oeg27 { height: 320px; overflow: hidden; position: relative; width: 1041px; }", ".framer-aeSV8 .framer-19kerlm { flex: none; height: auto; left: 50%; position: absolute; top: 50%; white-space: pre; width: auto; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 320
 * @framerIntrinsicWidth 1041
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"NSkc44hTr":"title"}
 */
const FramerD9enZPm4w: React.ComponentType<Props> = withCSS(Component, css, "framer-aeSV8") as typeof Component;
export default FramerD9enZPm4w;

FramerD9enZPm4w.displayName = "TT";

FramerD9enZPm4w.defaultProps = {height: 320, width: 1041};

addPropertyControls(FramerD9enZPm4w, {NSkc44hTr: {defaultValue: "TICKER", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(FramerD9enZPm4w, [])